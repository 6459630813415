import { useState, memo } from "react";
import { useFormikContext } from "formik";
import TagTreeStep from "../v2/tag-tree-step";
import RecursiveTags from "../v2/recursive-tags";

import {
  checkIfLiteTemplate,
  getTagsForTemplateStep,
  getPreviousStepPath,
} from "utils/template-editor";
import { getNode } from "utils/tree";

import { ReactComponent as BackIcon } from "images/back-icon-blue-v2.svg";

import "./tag-option-tree.scss";
import config from "utils/config";

const TagOptionTree = (props) => {
  const {
    show,
    formikSteps,
    addTag,
    treeStyles,
    includeOnly = "",
    includeArraysOnly,
    stepHelpText,
    learnMoreUrl,
    hideAddFieldButton,
    isFieldList,
    isCustomTag,
    customTags,
    infoText,
    customStyle = {},
    showTagsWithDescription = false,
    appendedCustomTags,
    onStepSelect,
    selectedStepMachineName,
    path = [],
    currentStep = null,
    templateVersion = null,
  } = props;

  const { values } = useFormikContext();
  const [selectedTmplateStep, setSelectedTmplateStep] = useState(() => {
    if (path.length > 1) {
      // skipping the root nodes (Triggers)
      const prevStepPath = getPreviousStepPath(path);
      const prevStep = getNode(values, "steps", prevStepPath);
      if (
        prevStepPath.length > 0 &&
        prevStep &&
        prevStep.app_id === config.storage.appId
      ) {
        return null; // do not select the previous step if it is from the storage app
      }
      if (prevStepPath.length > 0 && prevStep) {
        return prevStep;
      }
    }
    return null;
  });

  let tags = null;
  let steps = null;

  if (!isCustomTag) {
    steps = appendedCustomTags
      ? [...formikSteps(), ...appendedCustomTags]
      : [...formikSteps()];
    steps = steps ? steps || {} : [];
    if (selectedTmplateStep && selectedTmplateStep.isCustom)
      tags = selectedTmplateStep.customTags;
    else {
      const templateSteps = formikSteps();
      tags = getTagsForTemplateStep({
        selectedTmplateStep,
        steps: { steps: templateSteps },
        includeOnly: includeOnly || selectedTmplateStep?.includeOnly || "",
      });
    }
  } else {
    tags = customTags;
  }
  const isliteTemplate = checkIfLiteTemplate();
  const addStepFields = (step) => {
    const { machine_name } = step;
    const tag = `{steps.${machine_name}.field_list}`;
    addTag(tag);
  };

  const onTemmplateStepClick = (step) => {
    if (isFieldList) {
      addStepFields(step);
    } else if (onStepSelect) {
      onStepSelect(step);
    } else {
      setSelectedTmplateStep(step);
    }
  };

  return (
    <>
      {show && (
        <div
          className={`tag-data-tree ${isliteTemplate ? "lite-data-tree" : ""}`}
          style={{ ...treeStyles, ...customStyle }}
          onClick={(e) => e.stopPropagation()}
        >
          {/*  <input placeholder="Search" /> */}
          {infoText && <div className="tag-data-tree_infotext">{infoText}</div>}
          {selectedTmplateStep && (
            <div>
              {stepHelpText && (
                <p className="tag-step-help-text">
                  {stepHelpText}
                  {learnMoreUrl && (
                    <a
                      href={learnMoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Learn more.
                    </a>
                  )}
                </p>
              )}

              <button
                className="clear-buton-css back-button"
                type="button"
                onClick={() => setSelectedTmplateStep(null)}
              >
                <BackIcon />
                Back to steps
              </button>
            </div>
          )}
          <div className="overflow-container">
            {isFieldList && (
              <p className="help-text">
                Only steps with valid customfields can be selected here
              </p>
            )}
            {!selectedTmplateStep && (
              <>
                {steps &&
                  steps.map(
                    (step, index) =>
                      ((step.activity_id &&
                        step.activity_id !== "http_client") ||
                        step.isCustom ||
                        step.is_form_step) && (
                        <TagTreeStep
                          {...step}
                          depth={0}
                          setSelectedTmplateStep={onTemmplateStepClick}
                          path={[index]}
                          stepActivity={step.activity}
                          key={index}
                          isFieldList={isFieldList}
                          selectedStepMachineName={
                            selectedStepMachineName ||
                            selectedTmplateStep?.machine_name
                          }
                        />
                      )
                  )}
              </>
            )}
            {(selectedTmplateStep || isCustomTag) && (
              <>
                {Object.keys(tags).map((objectKey) => (
                  <RecursiveTags
                    objectKey={objectKey}
                    value={tags[objectKey]}
                    parentKey=""
                    selectedTmplateStep={selectedTmplateStep}
                    addTag={addTag}
                    key={objectKey}
                    root
                    includeArraysOnly={includeArraysOnly}
                    hideAddFieldButton={hideAddFieldButton}
                    isFieldList={isFieldList}
                    isCustomTag={isCustomTag}
                    showWithDescription={showTagsWithDescription}
                    currentStep={currentStep}
                    templateVersion={templateVersion}
                    isExpandableOnly={
                      selectedTmplateStep?.isExpandableOnly?.[objectKey]
                    }
                  />
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TagOptionTree;
