/* eslint-disable @typescript-eslint/no-explicit-any */
/* 
Utils to generate tags for steps
*/

import {
  Step,
  Field,
} from "features/templates/template-builder-v7/services/template-builder-interface";
import { decodeJSON } from "utils/functions";
import config from "utils/config";

import {
  WorkspaceVariable,
  AppUserVariable,
} from "features/account-settings/variables/interface";

import storageIcon from "images/intg_view_storage.svg";

import { getStepFiledDefaultValue } from "./step-fields";

export const geTagsFromTestResult = (step: Step): Record<string, never> => {
  let testResult = {};
  if (step.test_output) {
    if (step.test_output?.out) {
      try {
        testResult = {
          ...testResult,
          out: JSON.parse(step.test_output?.out),
        };
      } catch (e) {
        console.log(e);
        testResult = {
          ...testResult,
          out: null,
        };
      }
    }
    if (step.test_output?.in) {
      try {
        testResult = {
          ...testResult,
          in: JSON.parse(step.test_output?.in),
        };
      } catch (e) {
        console.log(e);
        testResult = {
          ...testResult,
          in: null,
        };
      }
    }
  }
  return testResult;
};

const getOuputFromActivityField = ({
  activityFields,
  machineName,
}: {
  activityFields: Field[];
  machineName: string;
}): Record<string, any> => {
  let output = {};
  try {
    const activityField = activityFields.find(
      (field) =>
        (field.machine_name || field.activity_field_machine_name) ===
        machineName
    );
    if (activityField) {
      output = decodeJSON(activityField.default_value);
    }
  } catch (error) {
    console.error("Error in parsing activity output", error);
  }
  return output;
};

export const getCodeStepTags = (step: Step): Record<string, any> => {
  if (step?.activity?.type === "CODE") {
    try {
      const codeStepOutput =
        getOuputFromActivityField({
          activityFields: step.fields,
          machineName: "output",
        })?.result?.parsedResponseTemplate || "";
      return { out: decodeJSON(codeStepOutput), status: "" };
    } catch (error) {
      console.error("Error in parsing activity output", error);
    }
  }
  return {};
};

export const getConditionStepTags = (step: Step): Record<string, any> => {
  const conditionTags = {
    status: "",
    network_code: "",
  };
  return conditionTags;
};

export const getStorageTags = (
  steps: Step[]
): {
  runStorageTags: Record<string, any>;
  IntegrationStorageTags: Record<string, any>;
} => {
  const { storage } = config;
  const { set_value, append_value, increment_value, decrement_value } = storage;
  const storageActivityIds = [
    set_value.id.toString(),
    append_value.id.toString(),
    increment_value.id.toString(),
    decrement_value.id.toString(),
  ];
  let keys = {} as Record<string, any>;
  // recursive function to get all keys from storage steps
  const getKeys = (step: Step): Record<string, any> => {
    if (
      step.app_id === config.storage.appId &&
      storageActivityIds.includes(step.activity_id.toString())
    ) {
      const key = getStepFiledDefaultValue(step.fields, "key");
      const scope = getStepFiledDefaultValue(
        step.fields,
        "scope"
      ).toLowerCase();
      if (key) {
        keys = {
          ...keys,
          [scope]: {
            ...keys[scope],
            [key]: {
              value: "",
            },
          },
        };
      }
    }
    if (step.steps && step.steps.length > 0) {
      step.steps.forEach((s: Step) => getKeys(s));
    }
    return keys;
  };
  steps.forEach((step) => getKeys(step));

  const runStorageTags = {
    customTags: {
      ...(keys?.run || {}),
    },
    comments: "Run Variables",
    isCustom: true,
    customTagIcon: storageIcon,
    machine_name: "storage.run",
    isExpandableOnly: {
      run: true,
      integration: true,
    },
  };
  const IntegrationStorageTags = {
    customTags: {
      ...(keys?.integration || {}),
    },
    comments: "Integration Variables",
    isCustom: true,
    customTagIcon: storageIcon,
    machine_name: "storage.integration",
    isExpandableOnly: {
      run: true,
      integration: true,
    },
  };
  return { runStorageTags, IntegrationStorageTags };
};

export const transfromWorkspaceVariablesToTags = (
  variables: WorkspaceVariable[]
): Record<string, any> => {
  const tags = variables.reduce((acc, variable) => {
    const { key, value } = variable;
    return {
      ...acc,
      [key]: value,
    };
  }, {});

  const variablesTags = {
    customTags: tags,
    comments: "Workspace Variables",
    isCustom: true,
    machine_name: "storage.workspace",
    customTagIcon: storageIcon,
  };
  return variablesTags;
};

export const transfromAppUserVariablesToTags = (
  variables: AppUserVariable[]
): Record<string, any> => {
  const tags = variables.reduce((acc, variable) => {
    const { key, test_value } = variable;
    return {
      ...acc,
      [key]: test_value,
    };
  }, {});

  const variablesTags = {
    customTags: tags,
    comments: "User Variables",
    isCustom: true,
    machine_name: "storage.user",
    customTagIcon: storageIcon,
  };
  return variablesTags;
};
