/* eslint-disable import/prefer-default-export */
import {
  getHeadingForActivityType,
  getHeadingPrefixForConditionActivityType,
} from "utils/template-editor";
import startsWith from "lodash/startsWith";

import config from "utils/config";
import { Step } from "features/templates/template-builder-v7/services/template-builder-interface";
import { getStepFiledDefaultValue } from "./step-fields";

export const getTitleText = (templateStep: Step): string => {
  const { activity } = templateStep;
  switch (activity?.type) {
    case "SETUP":
      return "Setup";
    case "MAKE_HTTP_CALL":
      return "HTTP Call";
    case "LOOP":
      return "Loop";
    case "SCHEDULE":
      return "Schedule";
    case "VARIABLE":
      return "Set Var ";
    case "CODE":
      return config.code.label;
    case "TRIGGER":
      if (templateStep.app_id === config.incomingWebhook.appId)
        return "Webhook";
      if (activity || templateStep.activity) {
        return activity ? activity.name : templateStep.activity.name;
      }
      return "";
    case "ACTION":
    case "NOTIFICATION":
    case config.external_action.activityType:
      if (
        activity &&
        activity.app &&
        activity.app.id === config.storage.appId
      ) {
        const txt = `${activity.name} `;
        return txt;
      }
      if (
        templateStep.activity_id &&
        templateStep.activity_id === "http_client"
      ) {
        return "Custom Activity";
      }
      if (activity) {
        return activity && activity.name;
      }
      return "";
    default:
      if (activity) {
        return activity && activity.name;
      }
      return templateStep.title || "";
  }
};

export const getCollapsedStepTitle = (templateStep: Step): string => {
  const { storage } = config;
  const { set_value, append_value, increment_value, decrement_value } = storage;
  const { activity_type: activityType } = templateStep;
  if (startsWith(templateStep.activity_type, "CONDITION")) {
    return getHeadingPrefixForConditionActivityType(activityType);
  }
  if (templateStep.app_id === config.storage.appId) {
    const key = getStepFiledDefaultValue(templateStep.fields, "key");
    const value = getStepFiledDefaultValue(templateStep.fields, "value");
    switch (templateStep.activity_id) {
      case set_value.id:
        if (key && value) return `Set ${key}=${value}`;
        break;
      case append_value.id:
        if (key && value) return `Append ${value} to ${key}`;
        break;
      case increment_value.id:
        if (key) return `Increment ${key}`;
        break;
      case decrement_value.id:
        if (key) return `Decrement ${key}`;
        break;
      default:
        return templateStep.comments || templateStep.activity?.name;
    }
  }
  if (templateStep.comments) {
    return templateStep.comments;
  }
  return (
    getTitleText(templateStep) ||
    getHeadingForActivityType(templateStep.activity_type)
  );
};
